import { useEffect } from 'react';

import { useStore as siidStore } from '../../contexts/siid';
import useAuth from './useAuth';
import useConfig from './useConfig';

const useSiid = () => {
	const { data: { siid, siidNm, lastAuthStatus, header, footer, socialLinks }, dispatch } = siidStore();

	const { clientConfig } = useConfig();
	const { authenticated } = useAuth();

	// On load
	useEffect(() => {
		const sId = clientConfig('SIID');

		if (siid === null) {
			dispatch({ type: 'SET_SIID', payload: { siid: sId, siidNm: clientConfig('SIID_NM') }});
		}

		// Load config only if authentication status changed (but must be set!)
		if (authenticated !== null && lastAuthStatus !== authenticated) {
			import(`./siid/config_${sId}`).then((c) => {
				// Header
				dispatch({
					type: 'SET_HEADER_DATA', 
					payload: {
						nav: c[`headerNav${authenticated ? 'Auth' : 'NoAuth'}`],
						navProfile: c.headerNavProfile
					}
				});

				// Footer
				dispatch({
					type: 'SET_FOOTER_DATA', 
					payload: {
						navTop: c.footerNavTop,
						navBottom: c.footerNavBottom,
					}
				});

				// Social Links
				dispatch({
					type: 'SET_SOCIAL_LINKS', 
					payload: c.socialLinks
				});				
			});	

			dispatch({ type: 'SET_LAST_AUTH_STATUS', payload: authenticated });
		}		
	}, [authenticated]);

	return { siid, siidNm, header, footer, socialLinks };
};

export default useSiid;
