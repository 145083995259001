import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import PropTypes from 'prop-types';

import useHistory from '../_hooks/useHistory';
import useMeta from '../_hooks/useMeta';
import useConfig from '../_hooks/useConfig';

const DocumentHead = ({ data }) => {
	const { asPath } = useRouter();
	const { pushToHistory } = useHistory();
	const { clientConfig } = useConfig();

	const { getMeta } = useMeta();
	const [meta, setMeta] = useState(getMeta(data || null)); // getMeta(null)

	useEffect(() => {
		if (data) setMeta(getMeta(data || null));
	}, [data]);

	// Handle history
	useEffect(() => { pushToHistory({ asPath }); }, [asPath]);

	return (
		<Head>
			<title>{meta.title}</title>
			<meta name="title" content={meta.title} />
			<meta name="description" content={meta.description} />
			<meta name="keywords" content={meta.keywords} />
			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={meta.description} />
			{meta.image && <meta property="og:image" content={meta.image} />}
			{typeof window !== 'undefined' && <meta property="og:url" content={window.top.location.href} />}
			<meta name="twitter:card" content="summary_large_image" />
			<link rel="icon" href="/logos/logo-drazbe-color.svg" />
			<link rel="manifest" href="/manifest.json" />
			<meta name="theme-color" content="#6872E3" />
			<meta name="apple-mobile-web-app-status-bar-style" content="black" />
			<meta name="apple-mobile-web-app-title" content="Mojedrazbe.com" />
			<meta name="apple-mobile-web-app-capable" content="yes" />
			<meta name="mobile-web-app-capable" content="yes" />
			<link rel="apple-touch-icon" sizes="192x192" href="/logos/192x192.png" />
			<link rel="apple-touch-icon" sizes="512x512" href="/logos/512x512.png" />
			<link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
			<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
			{/* Global Site Tag (gtag.js) - Google Analytics */}
			<script
				async
				src={`https://www.googletagmanager.com/gtag/js?id=${clientConfig('GOOGLE_ANALYTICS_ID')}`}
			/>

			<script
				dangerouslySetInnerHTML={{ __html: `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${clientConfig('GOOGLE_ANALYTICS_ID')}', {
					page_path: window.location.pathname,
					});
				`
				}}
			/>
		</Head>
	);
};

DocumentHead.defaultProps = {
	data: {}
};

DocumentHead.propTypes = {
	data: PropTypes.shape({})
};

export default DocumentHead;
