import { useStore as appStore } from '../../contexts/app';

const useHistory = () => {
	const { data: appData, dispatch: appDispatch } = appStore();
	const { history } = appData;

	const pushToHistory = (payload) => {
		appDispatch({ type: 'PUSH_TO_HISTORY', payload });
	};

	const getPrev = () => {
		return history.length > 0 ? history[history.length - 1] : null;
	};

	const isPrevMatch = (pattern) => {
		const prev = getPrev();
		return prev ? prev.asPath.match(new RegExp(`${pattern}`, 'gi')) : null;
	};

	return { history, pushToHistory, getPrev, isPrevMatch };
};

export default useHistory;
